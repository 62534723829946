/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/05/2023.
 */
import {Button, Divider, Popconfirm, Radio, RadioChangeEvent} from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SUBJECT_STATUS_ENUM } from '../../Types'
import { BODY_PARTS, DEBUG } from '../../constants'
import { useEligibility } from '../../hooks/useEligibility'
import { useProtocol } from '../../hooks/useProtocol'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { EligibilityTable } from './eligibility/EligibilityTable'
import { StepsComponent } from './subject_update/StepsComponent'
import { useSubject } from '../../hooks/useSubject'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { mutate as mutateGlobal } from 'swr'

export type DataType = {
  calculation: {
    bodypart: string
    deviation: number
    mean: number
    values: number[]
  }[]
  debug: any
  eligibility: {
    days: number
    minDays: number
    averageMin: number
    averageMax: number
    deviationMin: number
    deviationMax: number
    rounding: 10 | 100 | 1000
  }
}

type Props = {
  open?: boolean
  onClose?: () => void
}

export const SubjectEligibilityView = ({ open, onClose }: Props) => {
  const { projectId, subjectId } = useParams() as { subjectId: string; projectId: string }

  const {
    data,
    mutate: mutateEligibility,
    isError,
    isLoading,
  }: { data: DataType; mutate: () => void; isError: any; isLoading: boolean } = useEligibility(projectId, subjectId)

  if (isLoading) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  return isError ? (
    <ErrorFallback error={isError} onClose={onClose} />
  ) : (
    <View data={data} mutateEligibility={mutateEligibility} onClose={onClose}></View>
  )
}

type ViewProps = {
  data: DataType
  mutateEligibility: () => void
  onClose: any
}

const View = ({ data, mutateEligibility, onClose }: ViewProps) => {
  const [openPop, setOpenPop] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const { projectId, subjectId } = useParams() as { subjectId: string; projectId: string }

  const { data: subjectData, mutate: mutateSubjectData } = useSubject(projectId, subjectId)

  const [selectedBodyPart, setSelectedBodyPart] = useState<string>()

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const cancel = () => {
    setOpenPop(false)
  }

  // Includes calculations from eligibility along with bodypart
  const eligibleBodyParts = data.calculation.map((item) => {
    return { bodyPart: item.bodypart, label: BODY_PARTS[item.bodypart] || item.bodypart }
  })
  const onChange = (e: RadioChangeEvent) => {
    setSelectedBodyPart(e.target.value)
  }

  const move = () => {
    setConfirmLoading(true)

    const obj = {
      subjectId: subjectData._id,
      bodyPart: selectedBodyPart,
      projectId: projectId,
    }

    return post(ENDPOINTS.SUBJECTS.ACTION.moveEligibility(projectId), obj, 'PUT')
      .then(() => {
        onClose()
        mutateSubjectData()
        mutateGlobal(ENDPOINTS.SUBJECTS.eligibleAssignments(projectId, subjectData._id))
        mutateGlobal(ENDPOINTS.SUBJECTS.useGroups(projectId, subjectId))
        setSelectedBodyPart(undefined)
        setConfirmLoading(false)
      })
      .catch((error) => {
        alert(error.message)
        setConfirmLoading(false)
      })
  }

  return (
    <div className="container bg-white">
      <div className="row" style={{}}>
        <div className="col-10">
          <div className="row">
            {/* HEADER SECTION */}
            <div className="col-12">
              <div className="row">
                <div>
                  <h6>Subject Eligibility Calculation </h6>

                  <p>
                    Based on the subject responses, we have calculated the eligibility for
                    <span className="fw-bold"> {subjectData.displayId}</span>
                  </p>

                  <div className="mt-3 px-3 py-3 rounded-2" style={{ background: '#e7f3ff' }}>
                    <p>
                      Please review the detailed assessment table below, which summarizes the average daily scores,
                      standard deviation and number of answers from the subject within the eligibility duration.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* TABLE SECTION */}
            <EligibilityTable data={data} mutateEligibility={mutateEligibility} />
          </div>

          {/* BODY PART SECTION & BUTTON */}
          <div className="col-12">
            <div className="row mt-4">
              <div className="">
                <h6>Please make your selection from the options below</h6>
                <p>
                  You are about to move the subject to the next phase of the study. Please select the target body part
                  for the subject:
                </p>
              </div>
              <div className="mt-3">
                <Radio.Group onChange={onChange}>
                  {eligibleBodyParts.map((option, index) => {
                    return (
                      <Radio value={option ? option.bodyPart : ''} key={index}>
                        {option.label ? option.label : ''}
                      </Radio>
                    )
                  })}
                </Radio.Group>
              </div>
            </div>
            <Divider></Divider>
            <div className="d-flex justify-content-end px-5">
              <Popconfirm
                  title="Confirmation: Move the subject?"
                  onConfirm={move}
                  open={openPop}
                  onCancel={cancel}
                  okButtonProps={{ loading: confirmLoading }}
                  okText="Confirm"
                  cancelText="Cancel"
              >
                <Button
                    type="primary"
                    onClick={showPopconfirm}
                    disabled={
                      !(
                          subjectData.status === SUBJECT_STATUS_ENUM.ACTIVE &&
                          (subjectData.onboard !== 'IS_SETUP' || subjectData.board !== 'ONBOARDED_COMPLETED') &&
                          selectedBodyPart
                      )
                    }
                >
                  Move to Next Phase
                </Button>
              </Popconfirm>
            </div>
          </div>

          {DEBUG ? (
            <div className="col-12">
              <div className="row" style={{}}>
                <div className="col-12">
                  <h5>Debug view {DEBUG.toString()}</h5>
                  <hr />
                </div>

                <div className="col-8">
                  <h5>Values </h5>
                  <pre>{JSON.stringify(data.debug, null, 2)}</pre>
                </div>
                <div className="col-4">
                  <h5>Eligibility config </h5>
                  <pre>{JSON.stringify(data.eligibility, null, 2)}</pre>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* TIMELINE / STEPS COMPONENT */}
        <div className="col-2 px-4 py-4">
          <PhaseStepComponent subjectData={subjectData}></PhaseStepComponent>
        </div>
      </div>
    </div>
  )
}

const PhaseStepComponent = ({ subjectData }: { subjectData: any }) => {
  const { projectId } = useParams() as { projectId: string }
  const { data: protocolData } = useProtocol(projectId)

  const phasesData = protocolData.phases
  return <StepsComponent phasesData={phasesData} subjectData={subjectData} />
}

const ErrorFallback = ({ error, onClose }: any) => {
  const clickker = () => {
    onClose()
  }

  return (
    <div className="container-fluid">
      <div role="alert" className="row ">
        <div className="col-8 p-4" style={{ background: '#f4f4f4' }}>
          <h4>Subject ineligible for calculation</h4>

          <p>{error.message}</p>

          <Button onClick={clickker} type="primary" className="mt-3">
            Close
          </Button>
        </div>
      </div>

      <pre>{error && error.errorData ? JSON.stringify(error.errorData, null, 4) : null}</pre>
    </div>
  )
}
