import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useMe = (projectId?: string) => {
  const path: string | null =
    projectId && projectId !== undefined && projectId !== 'undefined' ? ENDPOINTS.PROJECTS.GET_ME(projectId) : null

  const { data, error, mutate } = useSWR(path, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
