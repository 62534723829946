import { Card, Empty } from 'antd'
import dayjs from 'dayjs'
import { AUDIT_TYPES, DEVICE_STATE } from '../Types'
import utc from 'dayjs/plugin/utc'

export const AuditFactory = ({ data }) => {
  const hasData = data ? data.length > 0 : null
  dayjs.extend(utc)

  return (
    <>
      {hasData ? null : (
        <div className="row mt-4" style={{}}>
          <div className="col-12">
            <Empty description="No recorded audit logs"></Empty>
          </div>
        </div>
      )}
      <div className="row " style={{}}>
        <div className="col-12 px-0" style={{}}>
          {data?.map((item, index: number) => {
            // defaults to converting the time to the browser’s local timezone
            const created = dayjs.utc(item.createdAt).format('DD/MM/YYYY HH:mm:ss') + ' UTC'

            return (
              <Card key={index} bordered className="mb-3">
                <div key={index} className="row px-2 mb-3 ">
                  <div className="col-12 mt-1">
                    <h5 className="text-primary">{item.auditType || '-'}</h5>
                  </div>

                  <div className="col-12 d-flex justify-content-between">
                    <div className="">{buildChange(created, item)}</div>
                  </div>

                  {item.reason ? (
                    <div className="col-6 mt-1">
                      <p className="mt-2 p-small opacity-75">Reason for the change</p>
                      <p className="text-black">{item.reason.description ? item.reason.description : '-'}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Card>
            )
          })}
        </div>
      </div>
    </>
  )
}

const buildChange = (createdAt, item) => {
  switch (item.auditType) {
    // ------------------------
    // SUBJECT AUDITS
    // ------------------------
    case AUDIT_TYPES.CREATE_SUBJECT:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} created a subject with {spanWrap(item.targetUniqueId)}
          </div>
        </div>
      )
    case AUDIT_TYPES.UPDATE_SUBJECT:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} updated the subject's {spanWrap(item.targetUniqueId)} personal identifiable
            information
          </div>
        </div>
      )
    case AUDIT_TYPES.REQUEST_SUBJECT_PII:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} requested to view the subject's {spanWrap(item.targetUniqueId)} personal
            identifiable information
          </div>
        </div>
      )

    case AUDIT_TYPES.PORTAL_LOGOUT:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <p className="mt-1">{spanWrap(item.authorEmail)} </p>
          <p className="">logged out of the portal</p>
        </div>
      )

    case AUDIT_TYPES.TERMINATION:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} changed subject {spanWrap(item.targetUniqueId)} status from
            {spanWrap(item.before.status)} to
            {spanWrap(item.after.status)}
            with termination type
            {spanWrap(item.reason.type)}
          </div>
        </div>
      )
    case AUDIT_TYPES.REACTIVATION:
    case AUDIT_TYPES.COMPLETE:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} changed the status of subject {spanWrap(item.targetUniqueId)} from
            {spanWrap(item.before.status)} to
            {spanWrap(item.after.status)}
          </div>
        </div>
      )
    case AUDIT_TYPES.MOVE_BACK:
    case AUDIT_TYPES.MOVE:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} moved subject {spanWrap(item.targetUniqueId)} from
            {spanWrap(item.before.previousPhase)} to
            {spanWrap(item.after.nextPhase)} with
            {spanWrap(item.after.bodyPart === '' ? 'no selected body part' : item.after.bodyPart)}
          </div>
        </div>
      )
    case AUDIT_TYPES.UNCOMPLETE:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} updated subject {spanWrap(item.targetUniqueId)} status from
            {spanWrap(item.before.status)} to with change to their selected body part from
            {spanWrap(item.before.bodyPart ? item.before.bodyPart : '-')}
            to {spanWrap(item.after.bodyPart ? item.after.bodyPart : '-')}. The subject {spanWrap(item.targetUniqueId)}
            was moved into the {spanWrap(item.after.phase)}
          </div>
        </div>
      )
    case DEVICE_STATE.CREATED:
    case DEVICE_STATE.ACTIVE:
    case DEVICE_STATE.DISABLE_DEVICE:
    case DEVICE_STATE.ENABLE_DEVICE:
    case DEVICE_STATE.WIPE_DEVICE:
    case DEVICE_STATE.WIPED:
    case DEVICE_STATE.ENROLL_NEW_DEVICE:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} updated device state for subject from
            {spanWrap(item.before.deviceState ? item.before.deviceState : 'no device')} to
            {spanWrap(item.after.deviceState)}
          </div>
        </div>
      )
    case AUDIT_TYPES.LOGIN_SUCCESS_SUBJECT:
      const deviceTime = item.before && item.before.deviceTime ? item.before.deviceTime : ''

      return (
        <div className="mt-1">
          <p className="p-small opacity-75">
            {
              <div className="d-flex">
                <div className="">{spanWrap(createdAt)} - </div>
                <div className="opacity-75 px-1 rounded-1" style={{ background: '' }}>
                  Device time: {deviceTime}
                </div>
              </div>
            }
          </p>
          <div className="mt-1">Subject successfully logged into the system</div>
        </div>
      )
    case AUDIT_TYPES.LOGIN_FAILED_SUBJECT:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">Subject failed to log into the system</div>
        </div>
      )
    case AUDIT_TYPES.SUBJECT_RESET_PIN_CONFIRM_CODE_SUCCESS:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">Subject successfully confirmed their PIN code reset</div>
        </div>
      )
    case AUDIT_TYPES.SUBJECT_RESET_PIN_CONFIRM_CODE_FAILED:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">Subject failed to confir their PIN code reset</div>
        </div>
      )
    case AUDIT_TYPES.SUBJECT_CHANGED_PIN:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">Subject has requested to change their PIN code</div>
        </div>
      )
    //------------------------
    // TRIAL STAFF / CLINICIAN AUDITS
    //------------------------
    case AUDIT_TYPES.CREATE_TRIALSTAFF:
    case AUDIT_TYPES.CREATE_CLINICIAN:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} created a personnel with email {spanWrap(item.targetUniqueId)}
          </div>
        </div>
      )

    //TODO: Update this one, not sure how to render it
    case AUDIT_TYPES.UPDATE_TRIALSTAFF:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <p className="mt-1">
            {spanWrap(item.authorEmail)} updated {spanWrap(item.targetUniqueId)}
          </p>
          {keyWrap(item)}
        </div>
      )
    case AUDIT_TYPES.UPDATE_TRIALSTAFF_SITES:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} updated site access for {spanWrap(item.targetUniqueId)}
            <p>
              from
              {item.before?.siteNumbers.map((siteNumber, index) => (
                <span className="mx-1 fw-bold" key={index}>
                  {siteNumber}
                </span>
              ))}
            </p>
            <p>
              to
              {item.after?.siteNumbers.map((siteNumber, index) => (
                <span className="mx-1 fw-bold" key={index}>
                  {siteNumber}
                </span>
              ))}
            </p>
          </div>
        </div>
      )

    //TODO: Update this one, not sure how to render it
    case AUDIT_TYPES.UPDATE_CLINICIAN_PII:
    case AUDIT_TYPES.UPDATE_TRIALSTAFF_PII:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} updated personnel {spanWrap(item.targetUniqueId)}
          </div>
          {keyWrap(item)}
        </div>
      )

    case AUDIT_TYPES.UPDATE_CLINICIAN_SITE_ACCESS:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} update site access for personnel {spanWrap(item.targetUniqueId)}
            <p>
              from
              {item.before?.siteNumbers.map((siteNumber, index) => (
                <span className="mx-1 fw-bold" key={index}>
                  {siteNumber}
                </span>
              ))}
            </p>
            <p>
              to
              {item.after?.siteNumbers.map((siteNumber, index) => (
                <span className="mx-1 fw-bold" key={index}>
                  {siteNumber}
                </span>
              ))}
            </p>
          </div>
        </div>
      )

    case AUDIT_TYPES.EXPORT_SITES_DATA:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} exported site data for sites:
            {item.before?.siteNumbers.map((value, index) => {
              return (
                <p className="mt-1" key={index}>
                  {value}
                </p>
              )
            })}
          </div>
        </div>
      )
    case AUDIT_TYPES.EXPORT_SUBJECT_DATA:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">{spanWrap(item.authorEmail)} exported subject data for this project</div>
        </div>
      )

    case AUDIT_TYPES.EXPORT_SUBJECT_AUDITS:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} exported all subject audits for sites:
            {item.before?.siteNumbers.map((value, index) => {
              return (
                <p className="mt-1" key={index}>
                  {value}
                </p>
              )
            })}
          </div>
        </div>
      )
    case AUDIT_TYPES.EXPORT_SUBJECT_ELIGIBILITY:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} exported eligibility data for sites:
            {item.before?.siteNumbers.map((value, index) => {
              return (
                <p className="mt-1" key={index}>
                  {value}
                </p>
              )
            })}
          </div>
        </div>
      )

    case AUDIT_TYPES.EXPORT_SUBJECT_AUDITS_EXTENDED:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} exported subject audits with eligibility for sites:
            {item.before?.siteNumbers.map((value, index) => {
              return (
                <p className="mt-1" key={index}>
                  {value}
                </p>
              )
            })}
          </div>
        </div>
      )

    case AUDIT_TYPES.SUBJECT_START_ASSIGNMENT:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">Subject started assignment: {spanWrap(item.targetUniqueId)}</div>
        </div>
      )

    case AUDIT_TYPES.INVITE:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} invited for the first time personnel with email
            {spanWrap(item.targetUniqueId)}
          </div>
        </div>
      )
    case AUDIT_TYPES.REINVITE:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} reinvited personnel with email
            {spanWrap(item.targetUniqueId)}
          </div>
        </div>
      )
    //---------------------------------
    // Training audit
    //---------------------------------
    case AUDIT_TYPES.TRAINING_COMPLETED:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">{spanWrap(item.authorEmail)} completed their training</div>
        </div>
      )

    case AUDIT_TYPES.SUBJECT_RESET_VERIFICATION_CODE_SENT:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">Reset pincode verification code sent to {spanWrap(item.authorEmail)}</div>
        </div>
      )

    case AUDIT_TYPES.SUBJECT_RESET_MISSING_EMAIL:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <p className="mt-1">
            Subject was <span className="fw-bold">unable</span> to obtain verification code for a pincode reset flow{' '}
          </p>
        </div>
      )

    case AUDIT_TYPES.SUBJECT_REPLACE_DEVICE:
      // Make backwards compatible
      // In version 1.0, device state is saved as a string on "before" and "after"
      // In post version 1.0, device state is saved as an object {deviceState: string} on "before" and "after"
      // Set a fallback value to an empty string
      const beforeState = typeof item?.before === 'string' ? item.before : item?.before?.deviceState || ''
      const afterState = typeof item?.after === 'string' ? item.after : item?.after?.deviceState || ''
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <p className="mt-1">
            <span className="fw-bold">{item.authorEmail}</span> updated the Subject device from{' '}
            <span className="fw-bold">{beforeState}</span> to <span className="fw-bold">{afterState}</span>
          </p>
        </div>
      )

    case AUDIT_TYPES.SUBJECT_ACTIVATE_DEVICE:
      console.log('auditFactory > item = ', item)
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <p className="mt-1">
            Device <span className="fw-bold"> {item.targetUniqueId}</span> was activated for subject
          </p>
        </div>
      )

    case AUDIT_TYPES.VIEW_ELIGIBILITY:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <p className="mt-1">
            Clinician <span className="fw-bold"> {item.authorEmail}</span> viewed the eligibility calculations for
            subject {spanWrap(item.targetUniqueId)}
          </p>
        </div>
      )

    case AUDIT_TYPES.MOVE_ELIGIBILITY:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} moved subject {spanWrap(item.targetUniqueId)} from
            {spanWrap(item.before.previousPhase)} to
            {spanWrap(item.after.nextPhase)} with
            {spanWrap(item.after.bodyPart === '' ? 'no selected body part' : item.after.bodyPart)} through the
            eligibility view
          </div>
        </div>
      )
    case AUDIT_TYPES.CREATE_GROUP_ACTION:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{spanWrap(createdAt)}</p>
          <div className="mt-1">
            {spanWrap(item.authorEmail)} registered the IMP for subject {spanWrap(item.targetUniqueId)} on
            {spanWrap(dayjs.utc(item.after.impTimestamp).format('DD/MM/YYYY HH:mm:ss'))}UTC
          </div>
        </div>
      )

    default:
      return (
        <div className="mt-1">
          <p className="p-small opacity-75">{item.auditType}</p>
          <div className="mt-1">Not handled</div>
        </div>
      )
  }
}

const spanWrap = (content) => {
  return <span className="fw-bold"> {content} </span>
}

const keyWrap = (item) => {
  return (
    <div className="mt-1">
      {Object.keys(item.before).map((key, index) => {
        const a = item.before[key]
        const b = item.after[key]

        return (
          <p className="mt-1" key={index}>
            {key}
            {spanWrap(a)} changed to {spanWrap(b)}
          </p>
        )
      })}
    </div>
  )
}
