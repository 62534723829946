import { useEffect } from 'react'
import { BODY_PARTS } from '../../../constants'

export const EligibilityTable = ({ data, mutateEligibility }) => {
  useEffect(() => {
    return () => {
      mutateEligibility()
    }
  }, [mutateEligibility])

  const calculation = data?.calculation
  const eligibility = data.eligibility

  return (
    <div className="container">
      <div className="col-12 px-2">
        <div className="row">
          <div className="col-12 border rounded-2 mt-4">
            <div className="row px-2 py-3 border-bottom" style={{ marginTop: 0, background: '#f8f8f8' }}>
              <div className="col-2 d-flex">Bodypart</div>
              <div className="col-3 d-flex justify-content-center">Average daliy pain</div>
              <div className="col-3 d-flex justify-content-center">Standard Deviation</div>
              <div className="col-2 d-flex justify-content-center">Answers</div>
              <div className="col-2 d-flex justify-content-center">Days in Phase</div>
            </div>
            {calculation &&
              calculation.map((item, index) => {
                const validDeviation =
                  item.deviation >= eligibility.deviationMin && item.deviation <= eligibility.deviationMax

                const validAverage = item.mean >= eligibility.averageMin && item.mean <= eligibility.averageMax
                // if amount of answers is less than minDays, it is invalid
                const validAnswerAmount = eligibility.minDays <= item.values.length

                const bodypartLabel = BODY_PARTS[item.bodypart] || item.bodypart

                const daysInStudy = data?.daysInStudy ? data.daysInStudy : 'N/A'

                return (
                  <div key={index} className="row p-2 border-bottom" style={{ marginTop: 0 }}>
                    <div className="col-2 border-end d-flex ">{bodypartLabel}</div>
                    <div className="col-3 border-end d-flex justify-content-center">
                      <p className={validAverage ? '' : 'text-danger fw-bold'}> {item.mean}</p>
                    </div>
                    <div className="col-3 border-end d-flex justify-content-center">
                      <p className={validDeviation ? '' : 'text-danger fw-bold'}> {item.deviation}</p>
                    </div>
                    <div className="col-2 border-end d-flex justify-content-center">
                      <p className={validAnswerAmount ? '' : 'text-danger fw-bold'}>{item.values.length}</p>
                    </div>
                    <div className="col-2 d-flex justify-content-center">
                      <p className={daysInStudy ? '' : 'text-danger fw-bold'}>{daysInStudy}</p>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <div>
        <p className="p-small mt-2 opacity-50">
          Legend: Values highlighted in red indicate areas where the reported data does not meet the eligibility
          criteria. Please review these details carefully.
        </p>
      </div>
    </div>
  )
}
