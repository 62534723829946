/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/05/2023.
 */
import { Button } from 'antd'
import { Suspense, useState } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { SubpageNavigator } from '../../components/navigator/SubpageNavigator'
import { DeviceDetails } from './DeviceDetails'
import { SubjectAuditTrial } from './SubjectAuditTrial'

import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import Spinner from '../../components/spinner/Spinner'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useEligibleAssignments } from '../../hooks/useEligibleAssignments'
import { useMe } from '../../hooks/useMe'
import { useProjectById } from '../../hooks/useProjectById'
import { useSubject } from '../../hooks/useSubject'
import { authStore } from '../../store/auth/authStore'
import { PROJECT_STATES, USER_TYPES } from '../../Types'
import { SubjectIMPModal } from './modals/SubjectIMPModal'
import { SubjectUpdateWrapper } from './subject_update/SubjectUpdateWrapper'
import { SubjectAssignment } from './SubjectAssignment'
import { SubjectAssignments } from './SubjectAssignments'
import { SubjectDetailsView } from './SubjectDetailsView'
import { SubjectEligibilityView } from './SubjectEligibilityView'
import { SubjectPii } from './SubjectPII'
import { useGroups } from '../../hooks/useGroups'

export const SubjectView = () => {
  const { projectId, subjectId } = useParams() as { subjectId: string; projectId: string }
  const userAtt = authStore((state) => state.user?.attributes)

  const { data: subjectData } = useSubject(projectId, subjectId)
  const { data: projectData } = useProjectById(projectId)

  const subpageMenu = [`Overview`, 'Device', 'Audit trail', 'Personal Information']

  const closeDrawer = () => {}

  // TODO - fix this - you should not look in the user type
  const showUpdateBtn = userAtt?.['custom:userType'] !== 'CLINICIAN' || projectData.status !== PROJECT_STATES.ACTIVE

  return (
    <>
      <div className="container-fluid bg-white ">
        <div className="container pt-5 pb-4 px-lg-6">
          <SubjectDetailsView
            subjectData={subjectData}
            child={
              !showUpdateBtn ? (
                <div className="">
                  <Link to={'./update'}>
                    <Button
                      className=""
                      type="primary"
                      disabled={
                        userAtt?.['custom:userType'] !== 'CLINICIAN' || projectData.status !== PROJECT_STATES.ACTIVE
                      }
                    >
                      Update Subject Status
                    </Button>
                  </Link>
                </div>
              ) : null
            }
          ></SubjectDetailsView>
        </div>
      </div>

      <SubpageNavigator menu={subpageMenu}>
        <Suspense fallback={<Spinner size={10}></Spinner>}>
          <SubjectOverview subjectData={subjectData}></SubjectOverview>
        </Suspense>

        <DeviceDetails></DeviceDetails>
        <Suspense fallback={<Spinner size={10}></Spinner>}>
          <SubjectAuditTrial></SubjectAuditTrial>
        </Suspense>
        <Suspense fallback={<Spinner></Spinner>}>
          <SubjectPii me={userAtt}></SubjectPii>
        </Suspense>
      </SubpageNavigator>

      <Routes>
        <Route
          path="/update"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <DrawerContainer
                projectId={projectId}
                setVisible={closeDrawer}
                width={300}
                to="../"
                title="Update Subject Status"
              >
                <SubjectUpdateWrapper />
              </DrawerContainer>
            </Suspense>
          }
        />
        <Route
          path="/assignment/:assignmentId"
          element={
            <DrawerContainer
              projectId={projectId}
              setVisible={closeDrawer}
              width={'50%'}
              to="../"
              title="Subject answers"
            >
              <SubjectAssignment></SubjectAssignment>
            </DrawerContainer>
          }
        />
        <Route
          path="/eligibility"
          element={
            <DrawerContainer
              projectId={projectId}
              setVisible={closeDrawer}
              width={'100%'}
              to="../"
              title="Subject Eligibility"
            >
              <SubjectEligibilityView></SubjectEligibilityView>
            </DrawerContainer>
          }
        />
      </Routes>
    </>
  )
}

const SubjectOverview = ({ subjectData }) => {
  const { projectId, subjectId } = useParams() as { projectId: string; subjectId: string }

  /**
   * TODO: Make eligibility button in its own component - WIP WIP WIP
   */

  const me = useMe(projectId)
  const eligibleAssignments = useEligibleAssignments(projectId, subjectId)

  const { data: groups, mutate: mutateGroups } = useGroups(projectId, subjectId)

  const [isIMPOpen, setIsIMPOpen] = useState(false)

  const showIMPModal = () => {
    setIsIMPOpen(true)
  }

  return (
    <div className="container ">
      <Routes>
        <Route
          path="/imp/:groupId"
          element={
            <SubjectIMPModal isIMPOpen={isIMPOpen} setIMPOpen={setIsIMPOpen} mutate={mutateGroups}></SubjectIMPModal>
          }
        />
      </Routes>

      {me.data.type === USER_TYPES.CLINICIAN && eligibleAssignments.data ? (
        <div className="row px-0" style={{}}>
          <div
            className="col-12 mb-2 px-4 d-flex justify-content-between  align-items-center rounded-2 py-3"
            style={{ background: '#e7f3ff' }}
          >
            <div className="">
              <p className="mb-0 mt-1 p-small" style={{ color: '#346ca8' }}>
                Click <span className="fw-bolder">"View Eligibility"</span> to check eligibility calculations for this
                subject.
              </p>
            </div>
            <div className="pe-3">
              <Link to={'eligibility'}>
                <Button type="primary">View Eligibility</Button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      {me.data.type === USER_TYPES.CLINICIAN && groups.length > 0 && subjectData.onboard === 'ONBOARDED_COMPLETED'
        ? groups.map((group, index) => {
            return (
              <div key={index} className="row px-0" style={{}}>
                <div
                  className="col-12 d-flex justify-content-center mb-2 px-4 rounded-2 py-4"
                  style={{ background: '#e7f3ff' }}
                >
                  <div className="">
                    <p className="mb-0 fw-bold mt-1 text-center" style={{ color: '#346ca8' }}>
                      {group.cta.title || '---'}
                    </p>
                    <p className="mb-0 mt-1 mb-2 p-small text-center" style={{ color: '#346ca8' }}>
                      {group.cta.description}
                    </p>

                    <div className="d-flex justify-content-center">
                      <Link to={'imp/' + group._id}>
                        <Button type="primary" onClick={showIMPModal}>
                          {group.cta.label}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        : null}

      <div className="row" style={{}}>
        <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
          <SubjectAssignments />
        </Suspense>
      </div>
    </div>
  )
}
