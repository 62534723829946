//
// the overall userType is also stored on the token
// as either clinician or trialStaff
//

export type COUNTRY_TYPE = {
  abbreviation: string
  countryCode: string
  name: string
}
export const TOKEN_USER_TYPES = {
  CLINICIAN: 'CLINICIAN',
  TRIAL_STAFF: 'TRIAL_STAFF',
} as const

export enum PROJECT_STATES {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
  ARCHIVED = 'ARCHIVED',
}

//
// These user types are stored on the user document
//
export const USER_TYPES = {
  CTM: 'CTM',
  CRA: 'CRA',
  DM: 'DM',
  CLINICIAN: 'CLINICIAN',
  TRIAL_STAFF: 'TRIAL_STAFF',
} as const

export type UserTypes = keyof typeof USER_TYPES
export type TokenUserTypes = keyof typeof TOKEN_USER_TYPES

export enum TERMINATION_REASONS {
  DISCONTINUED = 'DISCONTINUED',
  SCREEN_FAILURE = 'SCREEN_FAILURE',
  OTHER = 'OTHER',
}

export enum SUBJECT_STATUS_ENUM {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
  IN_STUDY = 'IN_STUDY', // should be removed
  DISQUALIFIED = 'DISQUALIFIED', // should be removed
  COMPLETED = 'COMPLETED',
}

export enum TRIAL_STAFF_TYPE_ENUM {
  CTM = 'CTM',
  CRA = 'CRA',
  DM = 'DM',
}

export enum CRUD_VIEW_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW',
}

export type SubjectType = {
  _id: string
  displayId: string
  screeningId: string
  createdBy: string
  deviceId: string
  onboard: string
  currentPhase: { title: string; duration: number }
  projectId: string
  siteId: { siteNumber: string; title: string }
  createdAt: Date
  status: string
  language: string
  bodyPart: string
}

export type AssignmentType = {
  _id: string
  answers: any[]
  createdAt: string
  endDate: string
  name: string
  phaseId: string
  progress: number
  projectId: string
  questionnaireId: string
  questions: any[]
  recurrence: string
  recurrenceType: string
  startDate: string
  sub: string
  subjectId: string
}

/*createdBy: '649b08ca19f44f51ca820450'
deviceId: 'PghRoWFNuRx91D2dYrqVr'
language: 'EN'
onboard: 'ONBOARDED'
phaseId: '642d89599b3dfb52c68ffd53'
projectId: '63fefd5046c654ee95247cc5'
screeningId: 'tg153-078b__0444'
siteId: '643fe16281d0e26bb7eb4b6c'
_id: '6529ddd685024b8d02d7066d'*/

/*export type FormConfigObject = {
  id: string
  type: 'PHONE'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  value: string
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  //stateType: 'CREATE' | 'VIEW' | 'UPDATE'
}*/

export type SiteType = {
  _id: string
  projectId: string
  siteNumber: number
  title: string
  address: string
  phone: { tel: number; countryCode: string }
  contactPersonName: string
  contactPersonJobTitle: string
  contactPersonEmail: string
  active: boolean
  createdAt: Date
  timeZone: string
  updatedAt: Date
}

export type Translation = {
  values: {
    language: string // ISO country code
    value: string // value of the string
  }[]
}

// See https://www.loc.gov/standards/iso639-2/php/English_list.php for specification, add as needed
// Not sure if something built in is smarter for this though...
export type LanguageISOCode = 'en' | 'es' | 'da'

export const QuestionsTypes = {
  radio: 'radio',
  checkbox: 'checkbox',
  text: 'text',
  number: 'number',
  info: 'info',
  bmi: 'bmi',
  phonenumber: 'phonenumber',
  name: 'name',
  gender: 'gender',
  birthdate: 'birthdate',
  nprs5: 'nprs5',
  nprs11pt5l: 'nprs11pt5l',
  nprs11pt2l: 'nprs11pt2l',
  yesno: 'yesno',
} as const
export type QuestionType = keyof typeof QuestionsTypes

export interface Question {
  _id: string
  questionnaireId: string
  title: Translation
  body: Translation
  continuation: Translation
  type: QuestionType
  lastModified: Date
  next: (string | null)[]
  storage: string
  required: boolean
  externalStorageId?: string
}

export const DEVICE_STATE = {
  ENROLL_NEW_DEVICE: 'ENROLL_NEW_DEVICE',
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLE_DEVICE: 'DISABLE_DEVICE',
  ENABLE_DEVICE: 'ENABLE_DEVICE',
  WIPE_DEVICE: 'WIPE_DEVICE',
  WIPED: 'WIPED',
} as const

export type DEVICE_STATE_TYPE = keyof typeof DEVICE_STATE

export enum AUDIT_TYPES {
  TERMINATION = 'TERMINATION',
  REACTIVATION = 'REACTIVATION',
  MOVE = 'MOVE',
  MOVE_ELIGIBILITY = 'MOVE_ELIGIBILITY',
  MOVE_BACK = 'MOVE_BACK',
  VIEW_ELIGIBILITY = 'VIEW_ELIGIBILITY',
  COMPLETE = 'COMPLETE',
  UNCOMPLETE = 'UNCOMPLETE',
  CREATE_CLINICIAN = 'CREATE_CLINICIAN',
  CREATE_TRIALSTAFF = 'CREATE_TRIALSTAFF',
  CREATE_SUBJECT = 'CREATE_SUBJECT',
  UPDATE_SUBJECT = 'UPDATE_SUBJECT',
  REQUEST_SUBJECT_PII = 'REQUEST_SUBJECT_PII',
  UPDATE_TRIALSTAFF = 'UPDATE_TRIALSTAFF',
  UPDATE_TRIALSTAFF_SITES = 'UPDATE_TRIALSTAFF_SITES',
  UPDATE_TRIALSTAFF_PII = 'UPDATE_TRIALSTAFF_PII',
  UPDATE_CLINICIAN_PII = 'UPDATE_CLINICIAN_PII',
  UPDATE_CLINICIAN_SITE_ACCESS = 'UPDATE_CLINICIAN_SITE_ACCESS',
  PORTAL_LOGOUT = 'PORTAL_LOGOUT',

  INVITE = 'INVITE',
  REINVITE = 'REINVITE',

  ACCESS = 'ACCESS',

  EXPORT_SUBJECT_DATA = 'EXPORT_SUBJECT_DATA',
  EXPORT_SITES_DATA = 'EXPORT_SITES_DATA',
  EXPORT_SUBJECT_AUDITS = 'EXPORT_SUBJECT_AUDITS',
  EXPORT_SUBJECT_ELIGIBILITY = 'EXPORT_SUBJECT_ELIGIBILITY',
  EXPORT_SUBJECT_AUDITS_EXTENDED = 'EXPORT_SUBJECT_AUDITS_EXTENDED',

  LOGIN_SUCCESS_SUBJECT = 'LOGIN_SUCCESS_SUBJECT',
  LOGIN_FAILED_SUBJECT = 'LOGIN_FAILED_SUBJECT',

  SUBJECT_RESET_MISSING_EMAIL = 'SUBJECT_RESET_MISSING_EMAIL',
  SUBJECT_CHANGED_PIN = 'SUBJECT_CHANGED_PIN',
  SUBJECT_RESET_VERIFICATION_CODE_SENT = 'SUBJECT_RESET_VERIFICATION_CODE_SENT',
  SUBJECT_RESET_PIN_CONFIRM_CODE_SUCCESS = 'SUBJECT_RESET_PIN_CONFIRM_CODE_SUCCESS',
  SUBJECT_RESET_PIN_CONFIRM_CODE_FAILED = 'SUBJECT_RESET_PIN_CONFIRM_CODE_FAILED',
  SUBJECT_REPLACE_DEVICE = 'SUBJECT_REPLACE_DEVICE',
  SUBJECT_ACTIVATE_DEVICE = 'SUBJECT_ACTIVATE_DEVICE',

  SUBJECT_START_ASSIGNMENT = 'SUBJECT_START_ASSIGNMENT',

  TRAINING_COMPLETED = 'TRAINING_COMPLETED',

  CREATE_GROUP_ACTION = 'CREATE_GROUP_ACTION',
}
