/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21/04/2023.
 */
import React, { Suspense } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useProjectById } from '../../hooks/useProjectById'
import { useMe } from '../../hooks/useMe'
import { USER_TYPES } from '../../Types'

//TODO: When you get props pass them, uncommented to avoid empty object pattern

export const HeaderBlock = () => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }
  const loc = useLocation()
  //const me = meStore((state) => state.me)

  //const showProject = projectId && me ? true : false
  const showProject = projectId ? true : false

  return (
    <>
      {showProject ? (
        <div className="container-fluid " style={{ background: '#323232', height: 40 }}>
          <div className="container px-2 h-100">
            {showProject ? (
              <div className="row">
                <Suspense fallback="...">
                  <Details projectId={projectId} siteId={siteId}></Details>
                </Suspense>
              </div>
            ) : (
              <div className="col-12 d-flex">
                <p className="me-1 text-white fw-bold">{loc.pathname}</p>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

const Details = ({ projectId, siteId }) => {
  const { data } = useProjectById(projectId)
  const { data: me } = useMe(projectId)

  const type = me?.type || ''

  if (!data) {
    return null
  }

  const abbreviations = data.country.map((country) => country.abbreviation).join(', ')
  const countryLabel = data.country.length > 1 ? 'Countries' : 'Country'
  const obj = [
    /*{
      label: 'Title',
      value: data?.title ? data?.title : '-',
    },*/
    {
      label: 'Protocol ID',
      id: 'protocolId',
      value: data?.activeProtocol ? data?.activeProtocol?.title : '-',
    },
    {
      label: 'Condition',
      id: 'condition',
      value: data?.condition ? data?.condition : '-',
    },
    /*{
      label: 'StudyId',
      value: data?.studyId ? data?.studyId : '-',
    },*/
    {
      label: countryLabel,
      id: 'country',
      value: data.country ? abbreviations : '-',
    },
    {
      label: 'Status',
      id: 'status',
      value: data?.status ? data?.status : '-',
    },
  ]

  return (
    <>
      <div className="col-4 ">
        {projectId && type === USER_TYPES.CLINICIAN
          ? me.sites.map((item, index) => {
              //const path = type === USER_TYPES.CLINICIAN ? item.gotoPage(projectId, siteId) : item.gotoPage(projectId)

              const isCurrent = item._id === siteId
              return isCurrent ? (
                <div key={index} className="p-small d-flex " style={{ marginTop: 10 }}>
                  <p className="text-capitalize me-1" style={{ fontSize: '.8rem', color: '#b4b4b4', opacity: 0.9 }}>
                    site:
                  </p>
                  <p className="text-capitalize text-white me-1 " style={{ fontSize: '.8rem', opacity: 0.9 }}>
                    {item.siteNumber}
                  </p>
                  <p className="text-capitalize me-1 " style={{ fontSize: '.8rem', color: '#b4b4b4', opacity: 0.9 }}>
                    /
                  </p>
                  <p className="text-capitalize text-white " style={{ fontSize: '.8rem', opacity: 0.9 }}>
                    {item.title}
                  </p>
                </div>
              ) : null
            })
          : null}
      </div>

      <div className="col-8 px-0 d-flex justify-content-end" style={{ marginTop: 10 }}>
        {obj.map((item, index) => {
          return (
            <div key={index} className="d-flex " style={{ marginRight: index === obj.length - 1 ? 0 : 14 }}>
              <p className="text-capitalize " style={{ fontSize: '.8rem', color: '#b4b4b4', opacity: 0.9 }}>
                {item.label}
              </p>
              <p
                className="text-white text-capitalize ms-1 text-truncate"
                style={{ fontSize: '.8rem', maxWidth: 120, opacity: 0.9 }}
              >
                {item.value}
              </p>
            </div>
          )
        })}
      </div>
    </>
  )
}
